<template>
  <b-table bordered fixed striped head-variant="dark" :fields="['month', 'amount']" :items="items">
    <template v-slot:cell(amount)="row">
      {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(row.item.amount) }}
    </template>
  </b-table>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    items () {
      const result = []

      if (this.paidInArrears === 'monthly') {
        const startDate = moment(this.startDate).endOf('month')
        const endDate = moment(this.endDate).endOf('month')
        const workingDate = moment(startDate)

        const months = endDate.diff(startDate, 'months')
        for (let i = 0; i < endDate.diff(this.startDate, 'months'); i++) {
          workingDate.add(1, 'months').endOf('month')
          result.push({ month: workingDate.format('MMMM YYYY'), amount: this.totalValue / months })
        }
      } else if (this.paidInArrears === 'quarterly') {
        const startDate = moment(this.startDate).endOf('month')
        const endDate = moment(this.endDate).endOf('quarter')
        const workingDate = moment(startDate)

        const quarters = endDate.diff(startDate, 'quarters')
        const months = moment(endDate).diff(startDate, 'months')
        for (let i = 0; i < months; i++) {
          workingDate.add(1, 'months').endOf('month')
          if (workingDate.isSame(moment(workingDate).endOf('quarter'))) {
            if (workingDate.isAfter(this.endDate)) {
              result.push({ month: workingDate.format('MMMM YYYY'), amount: ((this.totalValue / quarters) / 3) * (3 - workingDate.diff(moment(this.endDate).endOf('month'), 'months')) })
            } else if (moment(workingDate).add(-1, 'quarters').isBefore(startDate)) {
              result.push({ month: workingDate.format('MMMM YYYY'), amount: ((this.totalValue / quarters) / 3) * (3 - startDate.diff(moment(workingDate).add(-1, 'quarters'), 'months')) })
            } else {
              result.push({ month: workingDate.format('MMMM YYYY'), amount: this.totalValue / quarters })
            }
          } else {
            result.push({ month: workingDate.format('MMMM YYYY'), amount: 0 })
          }
        }
      } else if (this.paidPercentage === 'annually') {
        const startDate = moment(this.startDate).endOf('month')
        const endDate = moment(this.endDate).endOf('month')
        const workingDate = moment(startDate)
        const years = Math.ceil(moment(endDate).diff(startDate, 'years', true))

        const months = moment(endDate).diff(startDate, 'months')
        for (let i = 0; i < months; i++) {
          workingDate.add(1, 'months').endOf('month')
          if (i === 0) {
            result.push({ month: `${workingDate.format('MMMM YYYY')}${i === 0 ? ' - Contract Start Date' : ''}`, amount: ((this.percentages.sign ? (this.totalValue / 100 * (100 - this.percentages.sign)) / years : this.totalValue / years) / 100) * this.percentages.live })
          } else if (i % 12 === 0) {
            result.push({ month: `${workingDate.format('MMMM YYYY')} - Yearly Start & End Date`, amount: ((this.percentages.sign ? (this.totalValue / 100 * (100 - this.percentages.sign)) / years : this.totalValue / years) / 100) * (this.percentages.live + this.percentages.end) })
          } else if (i + 1 === months) {
            result.push({ month: `${workingDate.format('MMMM YYYY')}${i + 1 === months ? ' - Contract End Date' : ''}`, amount: ((this.percentages.sign ? (this.totalValue / 100 * (100 - this.percentages.sign)) / years : this.totalValue / years) / 100) * this.percentages.end })
          } else {
            result.push({ month: workingDate.format('MMMM YYYY'), amount: 0 })
          }
        }
        if (this.percentages.sign) {
          result.unshift({ month: `${startDate.format('MMMM YYYY')} - Signed`, amount: this.totalValue / 100 * this.percentages.sign })
        }
      } else if (this.paidPercentage === 'contract') {
        const startDate = moment(this.startDate).endOf('month')
        const endDate = moment(this.endDate).endOf('month')
        const workingDate = moment(startDate)

        const months = moment(endDate).diff(startDate, 'months')
        for (let i = 0; i < months; i++) {
          workingDate.add(1, 'months').endOf('month')
          if (i === 0) {
            result.push({ month: `${workingDate.format('MMMM YYYY')} - Contract Start Date`, amount: this.totalValue / 100 * this.percentages.live })
          } else if (i + 1 === months) {
            result.push({ month: `${workingDate.format('MMMM YYYY')} - Contract End Date`, amount: this.totalValue / 100 * this.percentages.end })
          } else {
            result.push({ month: workingDate.format('MMMM YYYY'), amount: 0 })
          }
        }
        if (this.percentages.sign) {
          result.unshift({ month: `${startDate.format('MMMM YYYY')} - Signed`, amount: this.totalValue / 100 * this.percentages.sign })
        }
      }

      return result
    }
  },
  props: {
    paidInArrears: {
      default: 'monthly',
      type: String
    },
    paidPercentage: {
      default: 'contract',
      type: String
    },
    percentages: {
      default: () => ({ sign: 0, live: 60, end: 40 }),
      type: Object
    },
    totalValue: {
      required: true,
      type: Number
    },
    startDate: {
      required: true,
      type: Date
    },
    endDate: {
      required: true,
      type: Date
    }
  }
}
</script>

<style>

</style>
