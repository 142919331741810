export const SUPPLIERS_BATCH_DELETE_REQUEST = 'SUPPLIERS_BATCH_DELETE_REQUEST'
export const SUPPLIERS_BATCH_DELETE_SUCCESS = 'SUPPLIERS_BATCH_DELETE_SUCCESS'
export const SUPPLIERS_BATCH_DELETE_FAILURE = 'SUPPLIERS_BATCH_DELETE_FAILURE'

export const SUPPLIERS_FETCH_MANY_REQUEST = 'SUPPLIERS_FETCH_MANY_REQUEST'
export const SUPPLIERS_FETCH_MANY_SUCCESS = 'SUPPLIERS_FETCH_MANY_SUCCESS'
export const SUPPLIERS_FETCH_MANY_FAILURE = 'SUPPLIERS_FETCH_MANY_FAILURE'

export const SUPPLIERS_FETCH_REQUEST = 'SUPPLIERS_FETCH_REQUEST'
export const SUPPLIERS_FETCH_SUCCESS = 'SUPPLIERS_FETCH_SUCCESS'
export const SUPPLIERS_FETCH_FAILURE = 'SUPPLIERS_FETCH_FAILURE'

export const SUPPLIERS_SAVE_REQUEST = 'SUPPLIERS_SAVE_REQUEST'
export const SUPPLIERS_SAVE_SUCCESS = 'SUPPLIERS_SAVE_SUCCESS'
export const SUPPLIERS_SAVE_FAILURE = 'SUPPLIERS_SAVE_FAILURE'
