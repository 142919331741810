<template>
  <b-container>
    <ResourceList
      can-delete create-to="energy.suppliers.create" resource="supplier" soft-deletes title="Suppliers"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #buttons>
        <!-- <b-dd-item-button :disabled="currentUserCan(permissions.CAN_SEND_PASSWORD_RESET_EMAIL)">Send Password Reset Email</b-dd-item-button> -->
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-row flex-fill">
          <b-avatar class="align-self-center mr-3" :src="supplierLogoUrl(item)" :text="item.name.split(' ').map(n => n && n.charAt(0)).slice(0, 2).join('').toUpperCase()" />
          <div class="d-flex flex-column flex-fill">
            <div class="d-flex flex-row">
              <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'energy.suppliers.update', params: { id: item.id }}">{{ item.name }}</router-link></p>
              <FriendlyDate class="align-self-end mb-1 text-muted" :date="item.created_at" />
            </div>
            <div class="d-flex flex-row mb-0">
              <p class="mb-0 flex-fill">{{ item.terms && item.terms.length }} payment terms</p>
            </div>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchManySuppliers(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('suppliers', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('suppliers', ['deleteManySuppliers', 'fetchManySuppliers']),
    supplierLogoUrl (supplier) {
      return new URL(process.env.VUE_APP_API_URL + supplier.logo).toString()
    },
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteManySuppliers({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManySuppliers(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManySuppliers({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManySuppliers({ ...this.$route.query, query })
      }
    }
  }
}
</script>

<style>

</style>
