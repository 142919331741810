<template>
  <router-view />
</template>

<script>
import supplierModule from '../../store/suppliers'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('suppliers')) {
      this.$store.registerModule('suppliers', supplierModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('suppliers')) {
      this.$store.unregisterModule('suppliers')
    }
  }
}
</script>
