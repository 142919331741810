import pagination from '../../utils/pagination'
import {
  SUPPLIERS_BATCH_DELETE_REQUEST, SUPPLIERS_BATCH_DELETE_SUCCESS, SUPPLIERS_BATCH_DELETE_FAILURE,
  SUPPLIERS_FETCH_MANY_REQUEST, SUPPLIERS_FETCH_MANY_SUCCESS, SUPPLIERS_FETCH_MANY_FAILURE,
  SUPPLIERS_FETCH_REQUEST, SUPPLIERS_FETCH_SUCCESS, SUPPLIERS_FETCH_FAILURE,
  SUPPLIERS_SAVE_REQUEST, SUPPLIERS_SAVE_SUCCESS, SUPPLIERS_SAVE_FAILURE
} from './types'

export default {
  [SUPPLIERS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [SUPPLIERS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [SUPPLIERS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [SUPPLIERS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [SUPPLIERS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
    state.validationErrors = {}
  },
  [SUPPLIERS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SUPPLIERS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [SUPPLIERS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [SUPPLIERS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [SUPPLIERS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [SUPPLIERS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [SUPPLIERS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  }
}
