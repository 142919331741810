<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Supplier</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="6">
                  <div class="d-flex flex-row align-items-end">
                    <b-avatar class="mr-3" :src="logoSrc.toString()" :text="name.split(' ').map(n => n && n.charAt(0)).slice(0, 2).join('').toUpperCase()" size="5rem" :url="file" />
                    <b-form-group class="flex-fill" label="Logo" :invalid-feedback="validationInvalidFeedback(errors, 'file')">
                      <b-form-file v-model="file" ref="fileInput"></b-form-file>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group class="flex-fill" label="Supplier Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')">
                    <b-form-input :disabled="isSaving" :state="validationState(errors, 'name')" v-model="name" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Payment Terms</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block variant="primary" v-b-modal.edit-payment-terms>Add Payment Terms</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-table class="mt-2" responsive bordered striped head-variant="dark" :fields="['paymentTerm', 'actions']" :items="paymentTerms">
                    <template v-slot:cell(paymentTerm)="row">
                      <p class="font-weight-bold h5 mb-0">{{ row.item.name }}</p>
                      <p class="mb-0">
                        <span v-if="row.item.how === 'arrears'"><em>Paid in arrears on a {{ row.item.when }} basis.</em></span>
                        <span v-if="row.item.how === 'percentages' && row.item.when === 'contract'"><em>{{ (row.item.perc.sign > 0) ? row.item.perc.sign + ' paid on signing up. ' : '' }}{{ row.item.perc.live }}% paid on contract start. {{ row.item.perc.end }}% paid on contract end.</em></span>
                        <span v-if="row.item.how === 'percentages' && row.item.when === 'annually'"><em>{{ (row.item.perc.sign > 0) ? row.item.perc.sign + ' paid on signing up. ' : '' }}{{ row.item.perc.live }}% paid on year start. {{ row.item.perc.end }}% paid on year end.</em></span>
                        <span v-if="row.item.how === 'upfront'"><em>{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(row.item.upfront) }} upfront payment</em></span>
                      </p>
                    </template>

                    <template v-slot:cell(actions)="row">
                      <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                    </template>

                    <template v-slot:row-details="row">
                      <b-card>
                        <p class="font-weight-bold h5 mb-1">Example Payments</p>
                        <p class="mb-4">Example payments if the total contract value is {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(examplePayments.totalValue) }}, between {{ examplePayments.startDate.format('MMMM YYYY') }} and {{ examplePayments.endDate.format('MMMM YYYY') }}.</p>

                        <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                        <b-button variant="primary" size="sm" @click="showEditPaymentTerm(row.index)" class="mr-2">Edit Payment Terms</b-button>
                        <b-button variant="danger" size="sm" @click="removePaymentTerm(row.index)" class="mr-2">Remove</b-button>

                        <payments-table class="my-4" v-if="row.item.how !== 'upfront'"
                          :end-date="examplePayments.endDate.toDate()"
                          :start-date="examplePayments.startDate.toDate()"
                          :paid-in-arrears="row.item.how === 'arrears' ? row.item.when : null"
                          :paid-percentage="row.item.how === 'percentages' ? row.item.when : null"
                          :percentages="row.item.perc"
                          :total-value="examplePayments.totalValue" />

                        <span v-if="row.item.how !== 'upfront'">
                          <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                          <b-button variant="primary" size="sm" @click="showEditPaymentTerm(row.index)" class="mr-2">Edit Payment Terms</b-button>
                          <b-button variant="danger" size="sm" @click="removePaymentTerm(row.index)" class="mr-2">Remove</b-button>
                        </span>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="edit-payment-terms" size="lg" cancel-title="Close" :ok-title="editPaymentTerms.index < -1 ? 'Save' : 'Create'"
              :title="editPaymentTerms.index < -1 ? 'Edit Payment Terms' : 'Create Payment Terms'" ok-variant="secondary" cancel-variant="dark"
              @ok="addPaymentTerm" @cancel="cancelPaymentTerm">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Payment Term Name">
                    <b-form-input type="text" v-model="editPaymentTerms.name" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="How will this supplier pay us?">
                    <b-form-radio-group buttons class="w-100" button-variant="primary" v-model="editPaymentTerms.how" :options="[{ text: 'In Arrears', value: 'arrears' }, { text: 'Percentages', value: 'percentages' }, { text: 'Upfront', value: 'upfront' }]"></b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="editPaymentTerms.how !== 'upfront'">
                  <b-form-group label="How often will the supplier pay us?">
                    <b-form-radio-group buttons class="w-100" button-variant="primary" v-model="editPaymentTerms.when" :options="howPaidOptions"></b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="editPaymentTerms.how === 'upfront'">
                  <b-form-group label="How large is the upfront payment?">
                    <b-input-group prepend="£">
                      <b-form-input type="number" step="0.01" v-model="editPaymentTerms.upfront"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="First Payment after Start Date">
                    <b-input-group append="days">
                      <b-form-input v-model.number="editPaymentTerms.first_payment" />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="editPaymentTerms.how === 'percentages'">
                  <b-form-group label="Percentages Paid">
                    <b-input-group>
                      <b-form-input title="Sign" v-model="editPaymentTerms.perc.sign" v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the contract is signed.'" />
                      <b-form-input title="Live" v-model="editPaymentTerms.perc.live" v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the supply of energy goes live, or the year starts.'" />
                      <b-form-input title="End"  v-model="editPaymentTerms.perc.end"  v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the contract ends, or the year ends.'" />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="editPaymentTerms.how !== 'upfront'">
                  <p class="h2 font-weight-bold">Example</p>
                  <p></p>
                  <p>This assumes a total contract value of {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(examplePayments.totalValue) }}, with the contract going live from {{ examplePayments.startDate.format('MMMM YYYY') }} to {{ examplePayments.endDate.format('MMMM YYYY') }}.</p>
                  <payments-table
                    :end-date="examplePayments.endDate.toDate()"
                    :start-date="examplePayments.startDate.toDate()"
                    :paid-in-arrears="paidInArrears"
                    :paid-percentage="paidPercentage"
                    :percentages="editPaymentTerms.perc"
                    :total-value="examplePayments.totalValue" />
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import momentMixin from '../../mixins/momentMixin'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

import PaymentsTable from '../../components/PaymentsTable'

export default {
  mixins: [momentMixin, validation],
  components: {
    PaymentsTable
  },
  created () {
    if (this.$route.params.id) {
      this.fetchSupplier(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('suppliers', ['errors', 'isLoading', 'isSaving', 'single']),
    paidInArrears () {
      return this.editPaymentTerms.how === 'arrears' ? this.editPaymentTerms.when : null
    },
    paidPercentage () {
      return this.editPaymentTerms.how === 'percentages' ? this.editPaymentTerms.when : null
    },
    howPaidOptions () {
      return this.editPaymentTerms.how === 'percentages'
        ? [{ text: 'Contract Period', value: 'contract' }, { text: 'Annually', value: 'annually' }]
        : [{ text: 'Monthly', value: 'monthly' }, { text: 'Quarterly', value: 'quarterly' }]
    },
    logoSrc () {
      return new URL(process.env.VUE_APP_API_URL + this.logo)
    }
  },
  data () {
    return {
      name: '',
      file: null,
      paymentTerms: [],
      examplePayments: {
        startDate: this.moment(),
        endDate: this.moment().add(18, 'months'),
        totalValue: 1200
      },
      editPaymentTerms: {
        name: '',
        how: 'arrears',
        when: 'monthly',
        first_payment: 30,
        upfront: 200,
        perc: {
          live: 80,
          sign: 0,
          end: 20
        }
      }
    }
  },
  methods: {
    ...mapActions('suppliers', ['fetchSupplier', 'saveSupplier']),
    addPaymentTerm () {
      if (typeof this.editPaymentTerms.index === 'undefined') {
        this.paymentTerms.push(this.editPaymentTerms)
      } else {
        this.paymentTerms.splice(this.editPaymentTerms.index, 1, this.editPaymentTerms)
      }

      this.$bvModal.hide('edit-payment-terms')
      this.editPaymentTerms = {
        name: '',
        how: 'arrears',
        when: 'monthly',
        upfront: 200,
        first_payment: 30,
        perc: {
          live: 80,
          sign: 0,
          end: 20
        }
      }
    },
    cancelPaymentTerm () {
      this.editPaymentTerms = {
        name: '',
        how: 'arrears',
        when: 'monthly',
        upfront: 200,
        first_payment: 30,
        perc: {
          live: 80,
          sign: 0,
          end: 20
        }
      }
    },
    removePaymentTerm (index) {
      this.paymentTerms.splice(index, 1)
    },
    showEditPaymentTerm (index) {
      this.editPaymentTerms = { ...this.paymentTerms[index], index }
      this.paymentTerms.splice(index, 1, this.editPaymentTerms)
      this.$bvModal.show('edit-payment-terms')
    },
    submit () {
      const data = new FormData()
      data.append('name', this.name)
      data.append('terms', JSON.stringify(this.paymentTerms))
      if (this.file) {
        data.append('logo', this.file, this.file.name)
      }

      this.saveSupplier({ id: this.$route.params.id, data }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Supplier ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This supplier has successfully been saved.'
        })

        if (!this.$route.params.id) {
          this.$router.push({ name: 'energy.suppliers.update', params: { id: response.data.id } })
        }
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.name = value.name
      this.logo = value.logo
      this.paymentTerms = value.terms || []
    },
    'editPaymentTerms.how' (val) {
      if (val === 'percentage') {
        this.editPaymentTerms.when = 'contract'
      }

      if (val === 'arrears') {
        this.editPaymentTerms.when = 'monthly'
      }
    }
  }
}
</script>

<style>

</style>
