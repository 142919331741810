import {
  SUPPLIERS_BATCH_DELETE_REQUEST,
  SUPPLIERS_BATCH_DELETE_SUCCESS,
  SUPPLIERS_BATCH_DELETE_FAILURE,
  SUPPLIERS_FETCH_MANY_REQUEST,
  SUPPLIERS_FETCH_MANY_SUCCESS,
  SUPPLIERS_FETCH_MANY_FAILURE,
  SUPPLIERS_FETCH_REQUEST,
  SUPPLIERS_FETCH_SUCCESS,
  SUPPLIERS_FETCH_FAILURE,
  SUPPLIERS_SAVE_REQUEST,
  SUPPLIERS_SAVE_SUCCESS,
  SUPPLIERS_SAVE_FAILURE
} from './types'

export default {
  deleteManySuppliers ({ commit }, { ids = [] }) {
    commit(SUPPLIERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/suppliers', { _method: 'DELETE', ids }).then(response => {
      commit(SUPPLIERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManySuppliers ({ commit }, { paginate = true, query = '', page = 1 }) {
    commit(SUPPLIERS_FETCH_MANY_REQUEST)
    return window.axios.get('/suppliers', { params: { paginate, query, page } }).then(response => {
      commit(SUPPLIERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchSupplier ({ commit }, id) {
    commit(SUPPLIERS_FETCH_REQUEST)
    return window.axios.get(`/suppliers/${id}`).then(response => {
      commit(SUPPLIERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIERS_FETCH_FAILURE, error)
      throw error
    })
  },
  saveSupplier ({ commit }, { id, data }) {
    const path = id ? `/suppliers/${id}` : '/suppliers'
    data.append('_method', id ? 'put' : 'post')

    commit(SUPPLIERS_SAVE_REQUEST)
    return window.axios.post(path, data).then(response => {
      commit(SUPPLIERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SUPPLIERS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SUPPLIERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  }
}
